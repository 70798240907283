:root {
  --min-screen-width: 320px;
  --base-background-image: linear-gradient(189deg, #191465 0%, #070525 93%);
  --base-text-color: #ededff;
  --header-logo-color: #cfcefa;
  --header-border-color: #362f99;
  --checked-question-button-background-color: rgba(217, 216, 246, 0.8);
  --garantee-background-color: #f0efff;
  --secure-link-color: #0a072d;
  --reviews-slider-card-border-radius: 25px;
  --reviews-slider-card-background-color: #f0efff;
  --subscription-description-color: #9d95d1;
  --payment-icon-color: #352e8b;
}

* {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;
}

body {
  min-width: var(--min-screen-width, 320px);
  min-height: var(--full-height, 100vh);
  color: #0a324d;
  background-image: var(--base-background-image);
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

#root {
  position: relative;
  height: var(--full-height, 100vh);
}

iframe {
  border: none;
}

::-webkit-scrollbar {
  width: 0;
}

/* Modern CSS Reset - https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements */
ul,
ol {
  list-style: none;
}

a {
  text-decoration-skip-ink: auto;
  text-decoration: none;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article>*+* {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}